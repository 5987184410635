@use "./animations";
@use "./variables";

@mixin box {
	border-radius: 10px;
	box-shadow: var(--box-shadow);
	padding: 30px;
	background: var(--background-color);

	@media screen and (max-width: variables.$mobile-breakpoint-small) {
		padding: 15px;
	}
}

@mixin middle {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin side_padding {
	padding-left: var(--side-padding-big);
	padding-right: var(--side-padding-big);
	
	@media screen and (max-width: variables.$mobile-breakpoint-very-big) {
		padding-left: var(--side-padding-medium);
		padding-right: var(--side-padding-medium);
	}

	@media screen and (max-width: variables.$mobile-breakpoint-small) {
		padding-left: var(--side-padding-small);
		padding-right: var(--side-padding-small);
	}
}

@mixin border_color {
	display: flex;
	flex-direction: column;
	position: relative;
	align-self: center;

	@media screen and (max-width: variables.$mobile-breakpoint-medium) {
		width: 100%;
	}

	&:before, &:after {
		content: '';
		position: absolute;
		left: -2px;
		top: -2px;
		background: linear-gradient(45deg, #4329ec, #e2c15d);
		background-size: 400%;;
		width: calc(100% + 4px);
		height: calc(100% + 4px);
		z-index: variables.$z-index-border-color;
		border-radius: 30px;
		animation: border_color_fade 20s linear infinite;
	}
	
	&:after {
		filter: blur(50px);
	}
}